<template>
  <div class="clearBox">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box">
      <ayl-main title="车队信息" :isCollapse="false" class="mt24px">
        <div slot="main" class="p24px">
          <div class="contents">
            <span>所属企业：</span>
            <span><b>{{companyName}}</b></span>
          </div>
          <div class="contents">
            <span>车队名称：</span>
            <span>{{ form.teamName ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车队负责人：</span>
            <span>{{ form.contacts ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>联系电话：</span>
            <span>{{ form.contactPhone ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>车队业务：</span>
            <span>{{ form.teamBusiness ||'- -' }}</span>
          </div>
          <div class="contents">
            <span>所属标段：</span>
            <span>{{ form.bidName ||'- -' }}</span>
          </div>
        </div>
      </ayl-main>
      <div class="tac" style="margin-top: 40px">
        <el-button style="margin-left: 30px" @click="onClose">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      nav: [{ name: "车队详情" }],
      company: null,
      companyName: null,
      form: {},
    };
  },
  methods: {
    onClose(){
      BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
    }
  },
  async mounted() {
    let companyInfo = await this.$api_hw.common_getTenantCompanyInfo({});
    this.companyName = companyInfo.label;
    this.company = companyInfo.value;
  },
  async activated() {
    this.form = await this.$api_hw.fleetManagement_getById({
      id: this.$route.query.id
    });
  }
};
</script>
<style lang='sass' scoped>
</style>
